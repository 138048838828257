<template>
  <div class="working-shift-wrapper page">
    <data-table
      :data="getData"
      hide-create
    >
      <template v-slot:buttonBlock>
        <el-popconfirm
          v-if="getOpenedWorkingShift"
          class="hot-btn"
          title="Точно закрыть смену?"
          @confirm="close"
        >
          <el-button
            slot="reference"
            class="el-button-h-40"
            plain
            type="warning"
          >
            <span>Закрыть смену</span>
          </el-button>
        </el-popconfirm>

        <el-button
          v-else
          class="el-button-h-40"
          plain
          type="primary"
          @click="open"
        >
          <span>Открыть смену</span>
        </el-button>
      </template>
    </data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DataTable from '@/components/dataTable'

export default {
  name: 'Categories',
  components: {
    DataTable
  },
  computed: {
    ...mapGetters('workingShift', ['getData', 'getOpenedWorkingShift'])
  },
  async created () {
    await this.loadData()
  },
  methods: {
    ...mapActions('user', { loadDataUser: 'loadData' }),
    ...mapActions('settings', { loadDataSettings: 'loadData' }),
    ...mapActions('workingShift', { loadDataWorkingShift: 'loadData' }),
    ...mapActions('modal', ['openModal']),
    ...mapActions('workingShift', ['open', 'close']),
    async loadData () {
      // TODO: Подумать над оптимизацией загрузки данных НА ВСЕХ стр.
      await this.$bus.emit('start-loader')
      await this.loadDataUser()
      await this.loadDataSettings()
      await this.loadDataWorkingShift()
      await this.$nextTick()
      await this.$bus.emit('stop-loader')
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />